<template>
  <div class="stocks">
    <page-header title="Акции" />
    <content-wrapper-block>
      <action-card
        v-for="(item, index) in specialsState"
        :key="index"
        style="margin-bottom: 20px"
        :title="item.title"
        :image="item.image"
        to="/"
      >
        {{ item.description }}
      </action-card>
    </content-wrapper-block>
    <div class="stocks-feedback">
      <callback-block id="callback" id-formblock="question-block__callback" />
    </div>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import { mapState } from "vuex";
import ActionCard from "@/components/elements/ActionCard";
import PageHeader from "@/components/elements/PageHeader";

export default {
  name: "StocksPage",
  components: {
    PageHeader,
    ActionCard,
    ContentWrapperBlock,
    CallbackBlock,
  },
  computed: {
    ...mapState({
      specialsState: (state) => state.specials,
    }),
  },
};
</script>

<style lang="scss" scoped>
.stocks {
  background: #231f20;

  &-feedback {
    background: #2e2a2b;
  }
}
</style>
