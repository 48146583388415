var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stocks" },
    [
      _c("page-header", { attrs: { title: "Акции" } }),
      _c(
        "content-wrapper-block",
        _vm._l(_vm.specialsState, function (item, index) {
          return _c(
            "action-card",
            {
              key: index,
              staticStyle: { "margin-bottom": "20px" },
              attrs: { title: item.title, image: item.image, to: "/" },
            },
            [_vm._v(" " + _vm._s(item.description) + " ")]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "stocks-feedback" },
        [
          _c("callback-block", {
            attrs: {
              id: "callback",
              "id-formblock": "question-block__callback",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }